<template>
    <section id="home_one_banner">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="banner_one_text">
                        <h1>Explore the world together</h1>
                        <h3>Find awesome tour packages filled with great experiences.</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "BannerHome"
};
</script>