<template>
    <!-- <section class="p-4">
        <div class="container">
            <div class="text-center">
                <h2>Services</h2>
                <p class="text-muted mb-5">These are our top feature and services for customers, tour agents, and tour
                    guides.
                </p>
            </div>
            <div class="row">
                <div class="col-sm-6 col-lg-4 mb-3 text-center">
                    <font-awesome-icon icon="credit-card-alt" class="services-icon" />
                    <h6>Find & Get Tour Packages as Customer</h6>
                    <p class="text-muted">Find the best tour packages available on the system.</p>
                </div>
                <div class="col-sm-6 col-lg-4 mb-3 text-center">
                    <font-awesome-icon icon="plane" class="services-icon" />
                    <h6>Provide Your Own Tour Packages as Tour Agent</h6>
                    <p class="text-muted">Provide and sell your tour packages on the system.</p>
                </div>
                <div class="col-sm-6 col-lg-4 mb-3 text-center">
                    <font-awesome-icon icon="compass" class="services-icon" />
                    <h6>Provide Guide Services as Tour Guide</h6>
                    <p class="text-muted">Sell your guiding services as a tour guide on the system.</p>
                </div>
            </div>
        </div>
    </section> -->
    <section id="top_service_andtour" class="mt-5">
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>Our Services</h2>
                        <p class="mt-3">These are our top feature and services for customers, tour agents, and tour guides.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <font-awesome-icon icon="credit-card-alt" class="services-icon"/>
                        <h3>Find & Get Tour Packages as Customer</h3>
                        <p>
                            Find the best tour packages available on the system. Find the most appealing and high-quality tour packages that are currently available for booking.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <font-awesome-icon icon="plane" class="services-icon"/>
                        <h3>Provide Your Own Tour Packages as Tour Agent</h3>
                        <p>
                            Provide and sell your tour packages on the system. 
                            We provide a platform for tour or travel agent to market and sell their tour packages to potential customers. 
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <font-awesome-icon icon="compass" class="services-icon"/>
                        <h3>Provide Guide Services as Tour Guide</h3>
                        <p>
                            Sell your guiding services as a tour guide on the system.
                            We provide a platform for tour guide to market and sell their services to potential customers. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "TopService"
};
</script>