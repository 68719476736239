<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light" style="height: 80px;">
    <div class="container-fluid">
      <button type="button" id="sidebarCollapse" class="btn btn-primary m-2 color-main-background">
        <font-awesome-icon icon="align-justify" />
        <span></span>
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {

  }
}
</script>

<style scoped>
.color-main-background {
  background-color: #184fa7;
}
.navbar {
  background: #fff;
  border: none;
  border-radius: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
</style>